$star-color: #501C5D;
$star-size: 4px;

// n is number of stars required
@function multiple-box-shadow($n, $color) {
    $value: "#{random(2000)}px #{random(2000)}px " + $color;
    @for $i from 2 through $n {
        $value: "#{$value} , #{random(2000)}px #{random(2000)}px " + $color;
    }
    @return unquote($value);
}

$shadows-small: multiple-box-shadow(700, lighten($star-color, 5%));
$shadows-medium: multiple-box-shadow(250, lighten($star-color, 3%));
$shadows-big: multiple-box-shadow(100, $star-color);
$shadows-huge: multiple-box-shadow(50, darken($star-color, 3%));

.stars {
    position: absolute;
    background: transparent;
    border-radius: 100%;

    &:after {
        content: " ";
        position: absolute;
        top: 2000px;
        background: transparent;
        border-radius: 100%;
    }
}

#stars {
    width: $star-size;
    height: $star-size;
    box-shadow: $shadows-small;

    &:after {
        width: $star-size;
        height: $star-size;
        box-shadow: $shadows-small;
    }
}

#stars2 {
    width: $star-size * 2;
    height: $star-size * 2;
    box-shadow: $shadows-medium;

    &:after {
        width: $star-size * 2;
        height: $star-size * 2;
        box-shadow: $shadows-medium;
    }
}

#stars3 {
    width: $star-size * 3;
    height: $star-size * 3;
    box-shadow: $shadows-big;

    &:after {
        width: $star-size * 3;
        height: $star-size * 3;
        box-shadow: $shadows-big;
    }
}

#stars4 {
    width: $star-size * 4;
    height: $star-size * 4;
    box-shadow: $shadows-huge;

    &:after {
        width: $star-size * 4;
        height: $star-size * 4;
        box-shadow: $shadows-huge;
    }
}

.stars-active:not(.no-animation) {
    #stars1 {
        animation: animStar 200s linear infinite;
    }

    #stars2 {
        animation: animStar 150s linear infinite;
    }

    #stars3 {
        animation: animStar 100s linear infinite;
    }

    #stars4 {
        animation: animStar 50s linear infinite;
    }
}

@keyframes animStar {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-2000px);
    }
}
